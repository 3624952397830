import staticContent from '@/components/static-content/index.vue';
import emptyData from "@/components/empty-data/index.vue"
import {mapGetters, mapActions, mapMutations} from 'vuex';
import mainSelect from '@/components/main-select/index.vue'

export default {
  name: 'article',
  data() {
    return {
      selectedFilter: '',
      filters: [
        {title: 'desc', value: 'a-z'},
        {title: 'asc', value: 'z-a'}
      ]
    }
  },
  components: {
    staticContent,
    emptyData,
    mainSelect
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  created() {
    this.fetchArticleData(this.$route.params.slug);
  },
  computed: {
    ...mapGetters({
      articleData: 'articles/articleData',
      isLoadingArticle: 'articles/isLoadingArticle',
    })
  },
  methods: {
    ...mapActions({
      fetchArticleData: `articles/ARTICLES_DATA_GET`,
    }),
    ...mapMutations({
      showPopup: 'articles/SHOW_ADD_COMMENT_POPUP',
      setArticleId: 'articles/SET_ARTICLE_ID'
    }),
    sendComment() {
      this.setArticleId(this.articleData.id)
      this.showPopup(true)
    }
  }
}
